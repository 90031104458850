var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "expenseCarouselView",
        "header-class": "header-class-modal-doc-package",
        size: `${_vm.expense.type === 2 ? "lg" : "xl"}`,
        centered: "",
        "cancel-variant": "light",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
      },
      on: { hidden: _vm.emitEventClose },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("div", { staticStyle: { "font-size": "1.25rem" } }, [
                      _vm._v(_vm._s(_vm.FormMSG(3, "Expense sheet review"))),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "4" } },
                    [
                      _c("div", { staticStyle: { "font-size": "1.25rem" } }, [
                        _c("span", { staticClass: "text-uppercase" }, [
                          _vm._v(
                            _vm._s(
                              _vm.FormMSG(
                                4 + _vm.expense.type,
                                _vm.EXPENSE_NAME[_vm.expense.type]
                              )
                            )
                          ),
                        ]),
                        _vm._v(
                          "\n\t\t\t\t\t#" +
                            _vm._s(_vm.item ? _vm.item.id : "") +
                            "\n\t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
                  _c("b-col", { attrs: { cols: "4" } }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-350-px mr-3",
                      staticStyle: { "margin-top": "8px" },
                      attrs: {
                        size: "sm",
                        variant: "custom-primary-blue",
                        disabled: _vm.watingSubmitForm,
                        block: "",
                      },
                      on: { click: _vm.validateExpense },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _c("b-spinner", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.watingSubmitForm,
                                expression: "watingSubmitForm",
                              },
                            ],
                            attrs: { small: "" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                              class: `${_vm.watingSubmitForm ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [
                              !_vm.watingSubmitForm
                                ? _c(
                                    _vm.getLucideIcon(
                                      _vm.ICONS.CHECK_SQUARE.name
                                    ),
                                    {
                                      tag: "component",
                                      staticClass: "mr-2",
                                      attrs: { size: 18 },
                                    }
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "2px",
                                    "letter-spacing": "0.05rem",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(
                                        2,
                                        "Validate Expense without rejected items"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "sm",
                        variant: "custom-outline-gray",
                        disabled: _vm.watingSubmitForm,
                        block: "",
                      },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(1, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          class:
            _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
        },
        [
          _vm.expenseItems.length > 1
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center",
                      },
                      [
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-transparent text-color-rhapsody-in-blue",
                              attrs: {
                                disabled: _vm.item ? _vm.index - 1 === 0 : true,
                              },
                              on: { click: _vm.back },
                            },
                            [
                              _c(_vm.getLucideIcon("ChevronLeft"), {
                                tag: "component",
                                attrs: { color: "rgba(6, 38, 62, 0.64)" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "px-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-color-rhapsody-in-blue fs-16 fw-40",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(7, "Expense item") +
                                      "  " +
                                      (_vm.item ? _vm.index : 1) +
                                      " / " +
                                      _vm.expenseItems.length
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-transparent text-color-rhapsody-in-blue",
                              attrs: {
                                disabled: _vm.item
                                  ? _vm.index === _vm.expenseItems.length
                                  : true,
                              },
                              on: { click: _vm.next },
                            },
                            [
                              _c(_vm.getLucideIcon("ChevronRight"), {
                                tag: "component",
                                attrs: { color: "rgba(6, 38, 62, 0.64)" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "wrap-status justify-content-center w-100" },
                  [
                    _c(
                      "div",
                      {
                        class: `status ${_vm.item ? _vm.item.statusClass : ""}`,
                        staticStyle: { "font-size": "0.84rem" },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.item ? _vm.item.validatedStatus : "") +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mt-3" },
                [_c("validation-viewer", { attrs: { item: _vm.item } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }