var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(1, "Refuse this expense item"),
        size: "md",
        centered: "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex flex-row justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "sm",
                        variant: "custom-outline-gray",
                        disabled: _vm.watingSubmitForm,
                        block: "",
                      },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(3, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px ml-2",
                      staticStyle: { "margin-top": "-1px" },
                      attrs: {
                        size: "sm",
                        variant: "custom-primary-blue",
                        disabled: _vm.watingSubmitForm || _vm.$v.$invalid,
                        block: "",
                      },
                      on: { click: _vm.save },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(4, "Save")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          class:
            _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("pre", [_vm._v(_vm._s(_vm.expenseItemId))]),
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(2, "Enter your comment") } },
                    [
                      _c("b-form-textarea", {
                        class: {
                          "is-invalid": _vm.$v.comment.$error,
                        },
                        attrs: { autocomplete: "off", rows: "3" },
                        model: {
                          value: _vm.comment,
                          callback: function ($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment",
                        },
                      }),
                      _vm.$v.comment.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(5, "Please, comment is required")
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }