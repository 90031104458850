var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "error-container",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "div",
        {
          staticClass: "p-0",
          class: {
            "container-layout": _vm.$screen.width >= 992,
            "container-mobile": _vm.$screen.width < 992,
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "form" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "h1",
                    {
                      class: [
                        `${
                          !_vm.$screen.sm && !_vm.$screen.md
                            ? "main-page-title"
                            : ""
                        }`,
                        { "is-pwa": _vm.$isPwa() },
                      ],
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(73, "Expense details")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-card",
                    {
                      staticStyle: { "background-color": "#fff" },
                      attrs: { "no-body": "" },
                    },
                    [
                      _vm.$screen.width >= 992
                        ? _c(
                            "b-row",
                            {
                              staticClass:
                                "back-with-title cursor-pointer d-flex align-items-center pt-0 pb-2 pl-0",
                              class: { "mt-16": _vm.$isPwa() },
                              staticStyle: { "margin-top": "-6px" },
                            },
                            [
                              _c("b-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-transparent",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push("/myexpenses")
                                      },
                                    },
                                  },
                                  [
                                    _c("ArrowLeft", {
                                      staticClass: "icon",
                                      attrs: {
                                        size: 22,
                                        color: "rgba(6, 38, 62, 0.65)",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "title-back-bolder mx-0" },
                                      [_vm._v(" " + _vm._s(_vm.caption1))]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "inline-flex align-items-center text-right py-0",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "header-date-title pr-3" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "wrap-status justify-content-end",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: `status ${_vm.classStatus(
                                                _vm.curExp.validated
                                              )}`,
                                              staticStyle: {
                                                "font-size": "0.7rem",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.labelStatus(
                                                      _vm.curExp.validated
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "name-title pr-3" },
                                    [
                                      _vm._v(_vm._s(_vm.curExp.fullName) + " "),
                                      _c("span", {
                                        staticClass: "divider-dot",
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.curExp.functionName)
                                      ),
                                    ]
                                  ),
                                  _vm.useEntryForCrewExpenses
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-end w-100 pr-3",
                                        },
                                        [
                                          !_vm.isFilmSingle
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "border-encoded p-1",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          149,
                                                          "Encoded by: "
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.curExp.encoder
                                                          .firstName
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.curExp.encoder
                                                            .name
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "header-name-title pr-3" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.dateToStringFormat(
                                              _vm.curExp.date
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c("span", {
                                        staticClass: "divider-dot",
                                      }),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.displayCurrency(
                                              _vm.curExp.amountTotal
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "back-with-title d-flex align-items-center cursor-pointer",
                                class: { "mt-16": _vm.$isPwa() },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.go(-1)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "icon" },
                                  [
                                    _c(_vm.getLucideIcon("ArrowLeft"), {
                                      tag: "component",
                                      attrs: { color: "#667987", size: 22 },
                                    }),
                                  ],
                                  1
                                ),
                                _c("h2", [_vm._v(_vm._s(_vm.caption1))]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "header-title-exp-detail text-center py-2",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "header-date-title pr-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "wrap-status justify-content-end",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: `status ${_vm.classStatus(
                                              _vm.curExp.validated
                                            )}`,
                                            staticStyle: {
                                              "font-size": "0.7rem",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.labelStatus(
                                                    _vm.curExp.validated
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "header-name-title pr-3" },
                                  [
                                    _vm._v(_vm._s(_vm.curExp.fullName) + " "),
                                    _c("span", { staticClass: "divider-dot" }),
                                    _vm._v(
                                      " " + _vm._s(_vm.curExp.functionName)
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "header-name-title pr-3" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.dateToStringFormat(
                                            _vm.curExp.date
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "divider-dot" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.displayCurrency(
                                            _vm.curExp.amountTotal
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                      _c(
                        "b-card-text",
                        {
                          class: `${
                            _vm.$screen.width >= 992 ? "px-3 pt-4" : "px-2 pt-3"
                          }`,
                        },
                        [
                          _vm.curExp.comment.length > 0
                            ? _c(
                                "div",
                                {
                                  class: `${
                                    _vm.$screen.width >= 992
                                      ? "card card-border-blue-light"
                                      : ""
                                  }`,
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  14,
                                                  "Comment:"
                                                ),
                                                "label-for": "comment",
                                              },
                                            },
                                            [
                                              _c("b-form-textarea", {
                                                attrs: {
                                                  disabled: true,
                                                  id: "comment",
                                                  rows: "2",
                                                },
                                                model: {
                                                  value: _vm.curExp.comment,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.curExp,
                                                      "comment",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "curExp.comment",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "card card-border-blue-light mt-3",
                              style: `${
                                _vm.$screen.width <= 992
                                  ? "margin-bottom: 112px;"
                                  : ""
                              }`,
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", xl: "12" } },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm.$screen.width < 992 &&
                                          _vm.curExp.type <
                                            _vm.EXPENSE_TYPE.TRAVEL
                                            ? _c("CardListBuilder", {
                                                attrs: {
                                                  "use-new-version": true,
                                                  items: _vm.expItems,
                                                  fields: _vm.omit(
                                                    ["validatedStatus"],
                                                    _vm.ticketFields
                                                  ),
                                                  "has-badge": true,
                                                  "is-badge-use-props": true,
                                                  "badge-class": "statusClass",
                                                  "hide-status": false,
                                                  "badge-value":
                                                    "validatedStatus",
                                                  "toggle-mode": true,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "rej",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-content-end align-items-center w-100",
                                                            },
                                                            [
                                                              data.item
                                                                .validated ===
                                                                _vm
                                                                  .VALIDATION_TYPE
                                                                  .REFUSED_LEVEL_1 ||
                                                              data.item
                                                                .validated ===
                                                                _vm
                                                                  .VALIDATION_TYPE
                                                                  .REFUSED_LEVEL_2
                                                                ? _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.ICONS
                                                                        .UNDO_2
                                                                        .name
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .UNDO_2
                                                                            .color,
                                                                        size: 22,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.undoRejectExpenseItem(
                                                                              data
                                                                                .item
                                                                                .id
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  )
                                                                : _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.ICONS
                                                                        .X_CIRCLE
                                                                        .name
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .X_CIRCLE
                                                                            .color,
                                                                        size: 22,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.rejectExpenseItem(
                                                                              data
                                                                                .item
                                                                                .id
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "pict",
                                                      fn: function (data) {
                                                        return [
                                                          data.item.images
                                                            .length > 0
                                                            ? _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .PICTURE
                                                                    .name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .PICTURE
                                                                        .color,
                                                                    size: 22,
                                                                    "stroke-width": 2.5,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showPicture(
                                                                          data
                                                                            .item
                                                                            .images
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "expenseItem",
                                                      fn: function (data) {
                                                        return [
                                                          _vm.canEditExpense
                                                            ? _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS.EDIT
                                                                    .name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .EDIT
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openEditExpense(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1295718730
                                                ),
                                              })
                                            : _vm._e(),
                                          _vm.$screen.width >= 992 &&
                                          _vm.curExp.type <
                                            _vm.EXPENSE_TYPE.TRAVEL
                                            ? _c(
                                                "data-table",
                                                {
                                                  attrs: {
                                                    value: _vm.expItems,
                                                    selectionMode: "single",
                                                    showGridlines: "",
                                                    resizableColumns: true,
                                                    scrollable: true,
                                                    scrollDirection:
                                                      "horizontal",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.ticketFields,
                                                  function (field, index) {
                                                    return _c(
                                                      "div",
                                                      { key: index },
                                                      [
                                                        !field.isDataTableSlot
                                                          ? _c("column", {
                                                              attrs: {
                                                                field:
                                                                  field.key,
                                                                header:
                                                                  field.label,
                                                                sortable:
                                                                  field.sortable &&
                                                                  field.sortable ===
                                                                    true
                                                                    ? true
                                                                    : false,
                                                                bodyStyle: {
                                                                  "justify-content":
                                                                    field.class ===
                                                                    "text-center"
                                                                      ? "center"
                                                                      : "end",
                                                                },
                                                                headerStyle: {
                                                                  "justify-content":
                                                                    "center",
                                                                  "background-color":
                                                                    field.backgroundColor
                                                                      ? field.backgroundColor
                                                                      : "none",
                                                                },
                                                                styles: {
                                                                  "flex-grow":
                                                                    "1",
                                                                  "flex-basis":
                                                                    field.flexBasis,
                                                                },
                                                                frozen:
                                                                  field.key ===
                                                                  "id",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        field.isDataTableSlot &&
                                                        field.key ===
                                                          "validatedStatus"
                                                          ? _c("column", {
                                                              attrs: {
                                                                field:
                                                                  field.key,
                                                                header:
                                                                  field.label,
                                                                sortable:
                                                                  field.sortable &&
                                                                  field.sortable ===
                                                                    true
                                                                    ? true
                                                                    : false,
                                                                bodyStyle: {
                                                                  "justify-content":
                                                                    field.class ===
                                                                    "text-center"
                                                                      ? "center"
                                                                      : "end",
                                                                },
                                                                headerStyle: {
                                                                  "justify-content":
                                                                    "center",
                                                                },
                                                                styles: {
                                                                  padding: 0,
                                                                  "flex-grow":
                                                                    "1",
                                                                  "flex-basis":
                                                                    field.flexBasis,
                                                                },
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "body",
                                                                      fn: function (
                                                                        slotProps
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "wrap-status d-flex",
                                                                            },
                                                                            [
                                                                              slotProps
                                                                                .data
                                                                                .isInContract ===
                                                                              false
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "justify-content-center align-items-center",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "button",
                                                                                        {
                                                                                          directives:
                                                                                            [
                                                                                              {
                                                                                                name: "b-tooltip",
                                                                                                rawName:
                                                                                                  "v-b-tooltip.left.html",
                                                                                                modifiers:
                                                                                                  {
                                                                                                    left: true,
                                                                                                    html: true,
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                          staticClass:
                                                                                            "btn-transparent text-color-rhapsody-in-blue",
                                                                                          attrs:
                                                                                            {
                                                                                              title:
                                                                                                _vm.tooltipContentOvertime(),
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            _vm.getLucideIcon(
                                                                                              "AlertTriangle"
                                                                                            ),
                                                                                            {
                                                                                              tag: "component",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    "#EA4E",
                                                                                                  size: 18,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "py-0",
                                                                                  class: `status ${slotProps.data.statusClass}`,
                                                                                  staticStyle:
                                                                                    {
                                                                                      "font-size":
                                                                                        "0.7rem",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        slotProps
                                                                                          .data
                                                                                          .validatedStatus
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            })
                                                          : _vm._e(),
                                                        field.isDataTableSlot &&
                                                        field.key === "date"
                                                          ? _c("column", {
                                                              attrs: {
                                                                field:
                                                                  field.key,
                                                                header:
                                                                  field.label,
                                                                sortable:
                                                                  field.sortable &&
                                                                  field.sortable ===
                                                                    true
                                                                    ? true
                                                                    : false,
                                                                bodyStyle: {
                                                                  "justify-content":
                                                                    field.class ===
                                                                    "text-center"
                                                                      ? "center"
                                                                      : "end",
                                                                },
                                                                headerStyle: {
                                                                  "justify-content":
                                                                    "center",
                                                                },
                                                                styles: {
                                                                  "flex-grow":
                                                                    "1",
                                                                  "flex-basis":
                                                                    field.flexBasis,
                                                                },
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "body",
                                                                      fn: function (
                                                                        slotProps
                                                                      ) {
                                                                        return [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.dateToStringFormat(
                                                                                  slotProps
                                                                                    .data
                                                                                    .date
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            })
                                                          : _vm._e(),
                                                        field.isDataTableSlot &&
                                                        field.key === "amount"
                                                          ? _c("column", {
                                                              attrs: {
                                                                field:
                                                                  field.key,
                                                                header:
                                                                  field.label,
                                                                sortable:
                                                                  field.sortable &&
                                                                  field.sortable ===
                                                                    true
                                                                    ? true
                                                                    : false,
                                                                bodyStyle: {
                                                                  "justify-content":
                                                                    field.class ===
                                                                    "text-center"
                                                                      ? "center"
                                                                      : "end",
                                                                },
                                                                headerStyle: {
                                                                  "justify-content":
                                                                    "center",
                                                                  "background-color":
                                                                    field.backgroundColor,
                                                                },
                                                                styles: {
                                                                  "flex-grow":
                                                                    "1",
                                                                  "flex-basis":
                                                                    field.flexBasis,
                                                                },
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "body",
                                                                      fn: function (
                                                                        slotProps
                                                                      ) {
                                                                        return [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.displayCurrency(
                                                                                  slotProps
                                                                                    .data
                                                                                    .amount
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            })
                                                          : _vm._e(),
                                                        field.isDataTableSlot &&
                                                        field.key ===
                                                          "amountTotal"
                                                          ? _c("column", {
                                                              attrs: {
                                                                field:
                                                                  field.key,
                                                                header:
                                                                  field.label,
                                                                sortable:
                                                                  field.sortable &&
                                                                  field.sortable ===
                                                                    true
                                                                    ? true
                                                                    : false,
                                                                bodyStyle: {
                                                                  "justify-content":
                                                                    field.class ===
                                                                    "text-center"
                                                                      ? "center"
                                                                      : "end",
                                                                },
                                                                headerStyle: {
                                                                  "justify-content":
                                                                    "center",
                                                                  "background-color":
                                                                    field.backgroundColor,
                                                                },
                                                                styles: {
                                                                  "flex-grow":
                                                                    "1",
                                                                  "flex-basis":
                                                                    field.flexBasis,
                                                                },
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "body",
                                                                      fn: function (
                                                                        slotProps
                                                                      ) {
                                                                        return [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.displayCurrency(
                                                                                  slotProps
                                                                                    .data
                                                                                    .amountTotal
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            })
                                                          : _vm._e(),
                                                        field.isDataTableSlot &&
                                                        field.key ===
                                                          "amountVat"
                                                          ? _c("column", {
                                                              attrs: {
                                                                field:
                                                                  field.key,
                                                                header:
                                                                  field.label,
                                                                sortable:
                                                                  field.sortable &&
                                                                  field.sortable ===
                                                                    true
                                                                    ? true
                                                                    : false,
                                                                bodyStyle: {
                                                                  "justify-content":
                                                                    field.class ===
                                                                    "text-center"
                                                                      ? "center"
                                                                      : "end",
                                                                },
                                                                headerStyle: {
                                                                  "justify-content":
                                                                    "center",
                                                                  "background-color":
                                                                    field.backgroundColor,
                                                                },
                                                                styles: {
                                                                  "flex-grow":
                                                                    "1",
                                                                  "flex-basis":
                                                                    field.flexBasis,
                                                                },
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "body",
                                                                      fn: function (
                                                                        slotProps
                                                                      ) {
                                                                        return [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.displayCurrency(
                                                                                  slotProps
                                                                                    .data
                                                                                    .amountVat
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            })
                                                          : _vm._e(),
                                                        field.isDataTableSlot &&
                                                        field.key === "splitVat"
                                                          ? _c("column", {
                                                              attrs: {
                                                                field:
                                                                  field.key,
                                                                header:
                                                                  field.label,
                                                                sortable:
                                                                  field.sortable &&
                                                                  field.sortable ===
                                                                    true
                                                                    ? true
                                                                    : false,
                                                                bodyStyle: {
                                                                  "justify-content":
                                                                    field.class ===
                                                                    "text-center"
                                                                      ? "center"
                                                                      : "end",
                                                                  "padding-top": 0,
                                                                  "padding-bottom": 0,
                                                                },
                                                                headerStyle: {
                                                                  "justify-content":
                                                                    "center",
                                                                },
                                                                styles: {
                                                                  "flex-grow":
                                                                    "1",
                                                                  "flex-basis":
                                                                    field.flexBasis,
                                                                },
                                                                alignFrozen:
                                                                  "right",
                                                                frozen: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "body",
                                                                      fn: function (
                                                                        slotProps
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "b-button",
                                                                            {
                                                                              staticClass:
                                                                                "btn bg-transparent border-0",
                                                                              attrs:
                                                                                {
                                                                                  size: "sm",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .PLUS_CIRCLE
                                                                                    .name
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#00a09c",
                                                                                      size: 20,
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.onExpenseItemSplitted(
                                                                                          slotProps
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            })
                                                          : _vm._e(),
                                                        field.isDataTableSlot &&
                                                        field.key === "kgCoTwo"
                                                          ? _c("column", {
                                                              attrs: {
                                                                field:
                                                                  field.key,
                                                                header:
                                                                  field.label,
                                                                sortable:
                                                                  field.sortable &&
                                                                  field.sortable ===
                                                                    true
                                                                    ? true
                                                                    : false,
                                                                bodyStyle: {
                                                                  "justify-content":
                                                                    field.class ===
                                                                    "text-center"
                                                                      ? "center"
                                                                      : "end",
                                                                },
                                                                headerStyle: {
                                                                  "justify-content":
                                                                    "center",
                                                                },
                                                                styles: {
                                                                  "flex-grow":
                                                                    "1",
                                                                  "flex-basis":
                                                                    field.flexBasis,
                                                                },
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "body",
                                                                      fn: function (
                                                                        slotProps
                                                                      ) {
                                                                        return [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.displayKgCo2(
                                                                                  slotProps
                                                                                    .data
                                                                                    .kgCoTwo
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            })
                                                          : _vm._e(),
                                                        field.isDataTableSlot &&
                                                        field.key === "actions"
                                                          ? _c("column", {
                                                              attrs: {
                                                                exportable: false,
                                                                styles: {
                                                                  padding: 0,
                                                                  "flex-grow":
                                                                    "1",
                                                                  "flex-basis":
                                                                    "12%",
                                                                },
                                                                bodyStyle: {
                                                                  "justify-content":
                                                                    "center",
                                                                },
                                                                headerStyle: {
                                                                  "justify-content":
                                                                    "center",
                                                                },
                                                                alignFrozen:
                                                                  "right",
                                                                frozen: "",
                                                                header:
                                                                  "Actions",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "body",
                                                                      fn: function (
                                                                        slotProps
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex justify-content-center align-items-center w-100",
                                                                            },
                                                                            [
                                                                              slotProps
                                                                                .data
                                                                                .images
                                                                                .length >
                                                                              0
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex justify-content-center",
                                                                                      staticStyle:
                                                                                        {
                                                                                          width:
                                                                                            "33%",
                                                                                          padding:
                                                                                            "7px 0",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "btn bg-transparent border-0",
                                                                                          attrs:
                                                                                            {
                                                                                              size: "sm",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.showPicture(
                                                                                                  slotProps
                                                                                                    .data
                                                                                                    .images
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            _vm.getLucideIcon(
                                                                                              _vm
                                                                                                .ICONS
                                                                                                .PICTURE
                                                                                                .name
                                                                                            ),
                                                                                            {
                                                                                              tag: "component",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    _vm
                                                                                                      .ICONS
                                                                                                      .PICTURE
                                                                                                      .color,
                                                                                                  size: 20,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.canEditExpense
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex justify-content-center",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "border-left":
                                                                                            "0.005rem solid rgba(226, 230, 235, 0.85)",
                                                                                          "border-right":
                                                                                            "0.005rem solid rgba(226, 230, 235, 0.85)",
                                                                                          width:
                                                                                            "34%",
                                                                                          padding:
                                                                                            "7px 0",
                                                                                        },
                                                                                      style: `${
                                                                                        slotProps
                                                                                          .data
                                                                                          .images
                                                                                          .length ===
                                                                                        0
                                                                                          ? "border-left: none !important; width: 50% !important"
                                                                                          : "width: 34% !important"
                                                                                      }`,
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "btn bg-transparent border-0",
                                                                                          attrs:
                                                                                            {
                                                                                              size: "sm",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.openEditExpense(
                                                                                                  slotProps.data
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            _vm.getLucideIcon(
                                                                                              _vm
                                                                                                .ICONS
                                                                                                .EDIT
                                                                                                .name
                                                                                            ),
                                                                                            {
                                                                                              tag: "component",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    _vm
                                                                                                      .ICONS
                                                                                                      .EDIT
                                                                                                      .color,
                                                                                                  size: 20,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex justify-content-center",
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "33%",
                                                                                      padding:
                                                                                        "7px 0",
                                                                                    },
                                                                                  style: `${
                                                                                    slotProps
                                                                                      .data
                                                                                      .images
                                                                                      .length ===
                                                                                    0
                                                                                      ? "width: 50% !important"
                                                                                      : "width: 33% !important"
                                                                                  }`,
                                                                                },
                                                                                [
                                                                                  slotProps
                                                                                    .data
                                                                                    .validated ===
                                                                                    _vm
                                                                                      .VALIDATION_TYPE
                                                                                      .REFUSED_LEVEL_1 ||
                                                                                  slotProps
                                                                                    .data
                                                                                    .validated ===
                                                                                    _vm
                                                                                      .VALIDATION_TYPE
                                                                                      .REFUSED_LEVEL_2
                                                                                    ? _c(
                                                                                        "b-button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "btn bg-transparent border-0",
                                                                                          attrs:
                                                                                            {
                                                                                              size: "sm",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.undoRejectExpenseItem(
                                                                                                  slotProps
                                                                                                    .data
                                                                                                    .id
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            _vm.getLucideIcon(
                                                                                              _vm
                                                                                                .ICONS
                                                                                                .UNDO_2
                                                                                                .name
                                                                                            ),
                                                                                            {
                                                                                              tag: "component",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    _vm
                                                                                                      .ICONS
                                                                                                      .UNDO_2
                                                                                                      .color,
                                                                                                  size: 20,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _c(
                                                                                        "b-button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "btn bg-transparent border-0",
                                                                                          attrs:
                                                                                            {
                                                                                              size: "sm",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.rejectExpenseItem(
                                                                                                  slotProps
                                                                                                    .data
                                                                                                    .id
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            _vm.getLucideIcon(
                                                                                              _vm
                                                                                                .ICONS
                                                                                                .X_CIRCLE
                                                                                                .name
                                                                                            ),
                                                                                            {
                                                                                              tag: "component",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    _vm
                                                                                                      .ICONS
                                                                                                      .X_CIRCLE
                                                                                                      .color,
                                                                                                  size: 20,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm.$screen.width < 992 &&
                                          _vm.curExp.type ===
                                            _vm.EXPENSE_TYPE.TRAVEL
                                            ? _c("CardListBuilder", {
                                                attrs: {
                                                  "use-new-version": true,
                                                  items: _vm.expItems,
                                                  fields: _vm.omit(
                                                    ["validatedStatus"],
                                                    _vm.kmFields
                                                  ),
                                                  "has-badge": true,
                                                  "is-badge-use-props": true,
                                                  "badge-class": "statusClass",
                                                  "hide-status": false,
                                                  "badge-value":
                                                    "validatedStatus",
                                                  "toggle-mode": true,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "rej",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-content-end align-items-center w-100",
                                                            },
                                                            [
                                                              data.item
                                                                .validated ===
                                                                _vm
                                                                  .VALIDATION_TYPE
                                                                  .REFUSED_LEVEL_1 ||
                                                              data.item
                                                                .validated ===
                                                                _vm
                                                                  .VALIDATION_TYPE
                                                                  .REFUSED_LEVEL_2
                                                                ? _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.ICONS
                                                                        .UNDO_2
                                                                        .name
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .UNDO_2
                                                                            .color,
                                                                        size: 22,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.undoRejectExpenseItem(
                                                                              data
                                                                                .item
                                                                                .id
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  )
                                                                : _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.ICONS
                                                                        .X_CIRCLE
                                                                        .name
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .X_CIRCLE
                                                                            .color,
                                                                        size: 22,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.rejectExpenseItem(
                                                                              data
                                                                                .item
                                                                                .id
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "expenseItem",
                                                      fn: function (data) {
                                                        return [
                                                          _vm.canEditExpense
                                                            ? _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS.EDIT
                                                                    .name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .EDIT
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openEditExpense(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  38236075
                                                ),
                                              })
                                            : _vm._e(),
                                          _vm.$screen.width >= 992 &&
                                          _vm.curExp.type ===
                                            _vm.EXPENSE_TYPE.TRAVEL
                                            ? _c(
                                                "data-table",
                                                {
                                                  attrs: {
                                                    value: _vm.expItems,
                                                    selectionMode: "single",
                                                    showGridlines: "",
                                                    resizableColumns: true,
                                                    scrollable: true,
                                                    scrollDirection:
                                                      "horizontal",
                                                  },
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.kmFields,
                                                    function (field, index) {
                                                      return _c(
                                                        "div",
                                                        { key: index },
                                                        [
                                                          !field.isDataTableSlot
                                                            ? _c("column", {
                                                                attrs: {
                                                                  field:
                                                                    field.key,
                                                                  header:
                                                                    field.label,
                                                                  sortable:
                                                                    field.sortable &&
                                                                    field.sortable ===
                                                                      true
                                                                      ? true
                                                                      : false,
                                                                  bodyStyle: {
                                                                    "justify-content":
                                                                      field.class ===
                                                                      "text-center"
                                                                        ? "center"
                                                                        : "end",
                                                                  },
                                                                  headerStyle: {
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                  styles: {
                                                                    "flex-grow":
                                                                      "1",
                                                                    "flex-basis":
                                                                      field.flexBasis,
                                                                  },
                                                                  frozen:
                                                                    field.key ===
                                                                    "id",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          field.isDataTableSlot &&
                                                          field.key ===
                                                            "validatedStatus"
                                                            ? _c("column", {
                                                                attrs: {
                                                                  field:
                                                                    field.key,
                                                                  header:
                                                                    field.label,
                                                                  sortable:
                                                                    field.sortable &&
                                                                    field.sortable ===
                                                                      true
                                                                      ? true
                                                                      : false,
                                                                  bodyStyle: {
                                                                    "justify-content":
                                                                      field.class ===
                                                                      "text-center"
                                                                        ? "center"
                                                                        : "end",
                                                                  },
                                                                  headerStyle: {
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                  styles: {
                                                                    padding: 0,
                                                                    "flex-grow":
                                                                      "1",
                                                                    "flex-basis":
                                                                      field.flexBasis,
                                                                  },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "body",
                                                                        fn: function (
                                                                          slotProps
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "wrap-status d-flex",
                                                                              },
                                                                              [
                                                                                slotProps
                                                                                  .data
                                                                                  .isInContract ===
                                                                                false
                                                                                  ? _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "justify-content-center align-items-center",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "button",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "b-tooltip",
                                                                                                  rawName:
                                                                                                    "v-b-tooltip.left.html",
                                                                                                  modifiers:
                                                                                                    {
                                                                                                      left: true,
                                                                                                      html: true,
                                                                                                    },
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                                                            attrs:
                                                                                              {
                                                                                                title:
                                                                                                  _vm.tooltipContentOvertime(),
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              _vm.getLucideIcon(
                                                                                                "AlertTriangle"
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "#EA4E",
                                                                                                    size: 18,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "py-0",
                                                                                    class: `status ${slotProps.data.statusClass}`,
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "0.7rem",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          slotProps
                                                                                            .data
                                                                                            .validatedStatus
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              })
                                                            : _vm._e(),
                                                          field.isDataTableSlot &&
                                                          field.key === "date"
                                                            ? _c("column", {
                                                                attrs: {
                                                                  field:
                                                                    field.key,
                                                                  header:
                                                                    field.label,
                                                                  sortable:
                                                                    field.sortable &&
                                                                    field.sortable ===
                                                                      true
                                                                      ? true
                                                                      : false,
                                                                  bodyStyle: {
                                                                    "justify-content":
                                                                      field.class ===
                                                                      "text-center"
                                                                        ? "center"
                                                                        : "end",
                                                                  },
                                                                  headerStyle: {
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                  styles: {
                                                                    "flex-grow":
                                                                      "1",
                                                                    "flex-basis":
                                                                      field.flexBasis,
                                                                  },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "body",
                                                                        fn: function (
                                                                          slotProps
                                                                        ) {
                                                                          return [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  _vm.dateToStringFormat(
                                                                                    slotProps
                                                                                      .data
                                                                                      .date
                                                                                  )
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              })
                                                            : _vm._e(),
                                                          field.isDataTableSlot &&
                                                          field.key === "km"
                                                            ? _c("column", {
                                                                attrs: {
                                                                  field:
                                                                    field.key,
                                                                  header:
                                                                    field.label,
                                                                  sortable:
                                                                    field.sortable &&
                                                                    field.sortable ===
                                                                      true
                                                                      ? true
                                                                      : false,
                                                                  bodyStyle: {
                                                                    "justify-content":
                                                                      field.class ===
                                                                      "text-center"
                                                                        ? "center"
                                                                        : "end",
                                                                  },
                                                                  headerStyle: {
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                  styles: {
                                                                    "flex-grow":
                                                                      "1",
                                                                    "flex-basis":
                                                                      field.flexBasis,
                                                                  },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "body",
                                                                        fn: function (
                                                                          slotProps
                                                                        ) {
                                                                          return [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  _vm.getDistanceTotalByUnit(
                                                                                    slotProps
                                                                                      .data
                                                                                      .km
                                                                                  ) +
                                                                                    " " +
                                                                                    _vm.distanceUnit
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              })
                                                            : _vm._e(),
                                                          field.isDataTableSlot &&
                                                          field.key ===
                                                            "kgCoTwo"
                                                            ? _c("column", {
                                                                attrs: {
                                                                  field:
                                                                    field.key,
                                                                  header:
                                                                    field.label,
                                                                  sortable:
                                                                    field.sortable &&
                                                                    field.sortable ===
                                                                      true
                                                                      ? true
                                                                      : false,
                                                                  bodyStyle: {
                                                                    "justify-content":
                                                                      field.class ===
                                                                      "text-center"
                                                                        ? "center"
                                                                        : "end",
                                                                  },
                                                                  headerStyle: {
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                  styles: {
                                                                    "flex-grow":
                                                                      "1",
                                                                    "flex-basis":
                                                                      field.flexBasis,
                                                                  },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "body",
                                                                        fn: function (
                                                                          slotProps
                                                                        ) {
                                                                          return [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  _vm.displayKgCo2(
                                                                                    slotProps
                                                                                      .data
                                                                                      .kgCoTwo
                                                                                  )
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              })
                                                            : _vm._e(),
                                                          field.isDataTableSlot &&
                                                          field.key ===
                                                            "amountTotal"
                                                            ? _c("column", {
                                                                attrs: {
                                                                  field:
                                                                    field.key,
                                                                  header:
                                                                    field.label,
                                                                  sortable:
                                                                    field.sortable &&
                                                                    field.sortable ===
                                                                      true
                                                                      ? true
                                                                      : false,
                                                                  bodyStyle: {
                                                                    "justify-content":
                                                                      field.class ===
                                                                      "text-center"
                                                                        ? "center"
                                                                        : "end",
                                                                  },
                                                                  headerStyle: {
                                                                    "justify-content":
                                                                      "center",
                                                                  },
                                                                  styles: {
                                                                    "flex-grow":
                                                                      "1",
                                                                    "flex-basis":
                                                                      field.flexBasis,
                                                                  },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "body",
                                                                        fn: function (
                                                                          slotProps
                                                                        ) {
                                                                          return [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  _vm.displayCurrency(
                                                                                    slotProps
                                                                                      .data
                                                                                      .amountTotal
                                                                                  )
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              })
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  _c("column", {
                                                    attrs: {
                                                      exportable: false,
                                                      styles: {
                                                        padding: 0,
                                                        "flex-grow": "1",
                                                        "flex-basis": "8%",
                                                      },
                                                      bodyStyle: {
                                                        "justify-content":
                                                          "center",
                                                      },
                                                      headerStyle: {
                                                        "justify-content":
                                                          "center",
                                                      },
                                                      alignFrozen: "right",
                                                      frozen: "",
                                                      header: "Actions",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "body",
                                                          fn: function (
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex justify-content-center align-items-center w-100",
                                                                },
                                                                [
                                                                  _vm.canEditExpense
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-content-center",
                                                                          staticStyle:
                                                                            {
                                                                              "border-right":
                                                                                "0.005rem solid rgba(226, 230, 235, 0.85)",
                                                                              width:
                                                                                "50%",
                                                                              padding:
                                                                                "7px 0",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-button",
                                                                            {
                                                                              staticClass:
                                                                                "btn bg-transparent border-0",
                                                                              attrs:
                                                                                {
                                                                                  size: "sm",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openEditExpense(
                                                                                      slotProps.data
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .EDIT
                                                                                    .name
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        _vm
                                                                                          .ICONS
                                                                                          .EDIT
                                                                                          .color,
                                                                                      size: 20,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex justify-content-center",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "50%",
                                                                          padding:
                                                                            "7px 0",
                                                                        },
                                                                    },
                                                                    [
                                                                      slotProps
                                                                        .data
                                                                        .validated ===
                                                                        _vm
                                                                          .VALIDATION_TYPE
                                                                          .REFUSED_LEVEL_1 ||
                                                                      slotProps
                                                                        .data
                                                                        .validated ===
                                                                        _vm
                                                                          .VALIDATION_TYPE
                                                                          .REFUSED_LEVEL_2
                                                                        ? _c(
                                                                            "b-button",
                                                                            {
                                                                              staticClass:
                                                                                "btn bg-transparent border-0",
                                                                              attrs:
                                                                                {
                                                                                  size: "sm",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.undoRejectExpenseItem(
                                                                                      slotProps
                                                                                        .data
                                                                                        .id
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .UNDO_2
                                                                                    .name
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        _vm
                                                                                          .ICONS
                                                                                          .UNDO_2
                                                                                          .color,
                                                                                      size: 20,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "b-button",
                                                                            {
                                                                              staticClass:
                                                                                "btn bg-transparent border-0",
                                                                              attrs:
                                                                                {
                                                                                  size: "sm",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.rejectExpenseItem(
                                                                                      slotProps
                                                                                        .data
                                                                                        .id
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .X_CIRCLE
                                                                                    .name
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        _vm
                                                                                          .ICONS
                                                                                          .X_CIRCLE
                                                                                          .color,
                                                                                      size: 20,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1117223792
                                                    ),
                                                  }),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  class: `${
                                    _vm.$screen.width <= 576
                                      ? "footer-fixed"
                                      : ""
                                  }`,
                                },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "3", sm: "12" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 d-flex justify-content-center",
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "d-flex justify-content-center align-items-center",
                                              class: `${
                                                _vm.$screen.width <= 576
                                                  ? "w-100 mb-2"
                                                  : "w-90"
                                              }`,
                                              attrs: {
                                                size: "md",
                                                variant: "outline-success",
                                              },
                                              on: {
                                                click: _vm.validateExpense,
                                              },
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.CHECK_SQUARE.name
                                                ),
                                                {
                                                  tag: "component",
                                                  staticClass: "mr-2",
                                                  attrs: { size: 20 },
                                                }
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "1px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        15,
                                                        "Validate Expense without rejected items"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "3", sm: "12" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 d-flex justify-content-center",
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "d-flex justify-content-center align-items-center",
                                              class: `${
                                                _vm.$screen.width <= 576
                                                  ? "w-100 mb-2"
                                                  : "w-90"
                                              }`,
                                              attrs: {
                                                size: "md",
                                                variant: "outline-primary",
                                                disabled: _vm.isLoadingDownload,
                                              },
                                              on: { click: _vm.downloadXls },
                                            },
                                            [
                                              _c("b-spinner", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.isLoadingDownload,
                                                    expression:
                                                      "isLoadingDownload",
                                                  },
                                                ],
                                                staticClass: "mr-2",
                                                attrs: { small: "" },
                                              }),
                                              !_vm.isLoadingDownload
                                                ? _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.DOWNLOAD.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      staticClass: "mr-2",
                                                      attrs: { size: 20 },
                                                    }
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "1px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        221,
                                                        "Download this Expense"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "3", sm: "12" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 d-flex justify-content-center",
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "d-flex justify-content-center align-items-center",
                                              class: `${
                                                _vm.$screen.width <= 576
                                                  ? "w-100 mb-2"
                                                  : "w-90"
                                              }`,
                                              attrs: {
                                                size: "md",
                                                variant: "outline-primary",
                                                disabled: _vm.isLoadingSendMail,
                                              },
                                              on: { click: _vm.sendXlsByEmail },
                                            },
                                            [
                                              _c("b-spinner", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.isLoadingSendMail,
                                                    expression:
                                                      "isLoadingSendMail",
                                                  },
                                                ],
                                                staticClass: "mr-2",
                                                attrs: { small: "" },
                                              }),
                                              !_vm.isLoadingSendMail
                                                ? _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.SEND.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      staticClass: "mr-2",
                                                      attrs: { size: 20 },
                                                    }
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "1px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        16,
                                                        "Send me this Expense by Email"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "3", sm: "12" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 d-flex justify-content-center",
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "d-flex justify-content-center align-items-center",
                                              class: `${
                                                _vm.$screen.width <= 576
                                                  ? "w-100 mb-2"
                                                  : "w-90"
                                              }`,
                                              attrs: {
                                                size: "md",
                                                variant: "outline-danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.modalRejectExpense = true
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.X_CIRCLE.name
                                                ),
                                                {
                                                  tag: "component",
                                                  staticClass: "mr-2",
                                                  attrs: { size: 20 },
                                                }
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "1px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        17,
                                                        "Reject Expense"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-modal",
                            {
                              staticClass: "modal-success",
                              attrs: {
                                "header-class":
                                  "header-class-modal-doc-package",
                                title: _vm.FormMSG(18, "Success!"),
                                "ok-variant": "success",
                                "ok-only": "",
                              },
                              on: {
                                ok: function ($event) {
                                  _vm.successModal = false
                                },
                              },
                              model: {
                                value: _vm.successModal,
                                callback: function ($$v) {
                                  _vm.successModal = $$v
                                },
                                expression: "successModal",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    this.FormMSG(
                                      19,
                                      "The expense has been sent to your Email address"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "b-modal",
                            {
                              staticClass: "modal-success",
                              attrs: {
                                "header-class":
                                  "header-class-modal-doc-package",
                                title: _vm.FormMSG(71, "Success!"),
                                "ok-variant": "success",
                                "ok-only": "",
                              },
                              on: {
                                ok: function ($event) {
                                  _vm.successModalValidated = false
                                },
                              },
                              model: {
                                value: _vm.successModalValidated,
                                callback: function ($$v) {
                                  _vm.successModalValidated = $$v
                                },
                                expression: "successModalValidated",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    this.FormMSG(
                                      23,
                                      "The expense has been validated"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "b-modal",
                            {
                              staticClass: "modal-danger",
                              attrs: {
                                "header-class":
                                  "header-class-modal-doc-package",
                                title: _vm.FormMSG(
                                  24,
                                  "Refuse this expense item"
                                ),
                                "ok-variant": "danger",
                                "ok-only": "",
                              },
                              on: { ok: this.finaliseExpenseReject },
                              model: {
                                value: _vm.modalRejectExpense,
                                callback: function ($$v) {
                                  _vm.modalRejectExpense = $$v
                                },
                                expression: "modalRejectExpense",
                              },
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      25,
                                      "Enter your comment:"
                                    ),
                                    "label-for": "comment",
                                    "label-cols": 4,
                                  },
                                },
                                [
                                  _c("b-form-textarea", {
                                    attrs: {
                                      id: "comment",
                                      placeholder: this.FormMSG(
                                        26,
                                        "Enter your comment"
                                      ),
                                      rows: "3",
                                    },
                                    model: {
                                      value: _vm.rejectComment,
                                      callback: function ($$v) {
                                        _vm.rejectComment = $$v
                                      },
                                      expression: "rejectComment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-modal",
                            {
                              staticClass: "modal-danger",
                              attrs: {
                                "header-class":
                                  "header-class-modal-doc-package",
                                title: _vm.FormMSG(
                                  24,
                                  "Refuse this expense item"
                                ),
                                "ok-variant": "danger",
                                "ok-only": "",
                              },
                              on: { ok: this.finaliseExpenseItemReject },
                              model: {
                                value: _vm.modalRejectedExpenseItem,
                                callback: function ($$v) {
                                  _vm.modalRejectedExpenseItem = $$v
                                },
                                expression: "modalRejectedExpenseItem",
                              },
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      25,
                                      "Enter your comment:"
                                    ),
                                    "label-for": "comment",
                                    "label-cols": 4,
                                  },
                                },
                                [
                                  _c("b-form-textarea", {
                                    attrs: {
                                      id: "comment",
                                      placeholder: this.FormMSG(
                                        26,
                                        "Enter your comment"
                                      ),
                                      rows: "3",
                                    },
                                    model: {
                                      value: _vm.rejectComment,
                                      callback: function ($$v) {
                                        _vm.rejectComment = $$v
                                      },
                                      expression: "rejectComment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-modal",
                            {
                              staticClass: "modal-danger",
                              attrs: {
                                "header-class":
                                  "header-class-modal-doc-package",
                                title: _vm.FormMSG(30, "Expense item comment"),
                                "ok-variant": "danger",
                                "ok-only": "",
                              },
                              on: {
                                ok: function ($event) {
                                  _vm.modalExpenseItemComment = false
                                },
                              },
                              model: {
                                value: _vm.modalExpenseItemComment,
                                callback: function ($$v) {
                                  _vm.modalExpenseItemComment = $$v
                                },
                                expression: "modalExpenseItemComment",
                              },
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(31, "Comment:"),
                                            "label-for": "comment",
                                            "label-cols": 2,
                                          },
                                        },
                                        [
                                          _c("b-form-textarea", {
                                            attrs: {
                                              disabled: true,
                                              id: "comment",
                                              rows: "2",
                                            },
                                            model: {
                                              value: _vm.expenseItemComment,
                                              callback: function ($$v) {
                                                _vm.expenseItemComment = $$v
                                              },
                                              expression: "expenseItemComment",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("script-export-modal", {
            attrs: {
              items: _vm.scripts,
              custom: _vm.customReport,
              "export-type": "expense",
            },
            on: {
              "script-export-modal:loading": _vm.handleLoading,
              "script-export-modal:closed": _vm.expenseRerpotOnClosed,
            },
            model: {
              value: _vm.isScriptExpenseReportOpen,
              callback: function ($$v) {
                _vm.isScriptExpenseReportOpen = $$v
              },
              expression: "isScriptExpenseReportOpen",
            },
          }),
          _c("ExpenseService", {
            key: _vm.expenseModalRefreshToken,
            attrs: {
              "expense-type": _vm.newExpenseType,
              "edit-data": _vm.currEditExpense,
            },
            on: {
              "expense-service-main:error": _vm.getError,
              submited: _vm.handleModalSubmited,
            },
            model: {
              value: _vm.isNewExpenseModalOpen,
              callback: function ($$v) {
                _vm.isNewExpenseModalOpen = $$v
              },
              expression: "isNewExpenseModalOpen",
            },
          }),
          _c("expense-carousel-view-modal", {
            attrs: {
              expense: _vm.curExp,
              "expense-item": _vm.expenseItem,
              "expense-items": _vm.expItems,
            },
            on: {
              "expense-carousel-view-modal:closed":
                _vm.onExpenseCarouselViewClosed,
              "expense-carousel-view-modal:reload":
                _vm.onExpenseCarouselViewReloaded,
            },
            model: {
              value: _vm.isExpenseCarouselViewOpen,
              callback: function ($$v) {
                _vm.isExpenseCarouselViewOpen = $$v
              },
              expression: "isExpenseCarouselViewOpen",
            },
          }),
          _c("expense-splitting-modal", {
            attrs: { "expense-item": _vm.expenseSplittingItem },
            on: {
              "expense-splitting-modal:closed": _vm.onExpenseSplittingClosed,
            },
            model: {
              value: _vm.isExpenseSplittingOpen,
              callback: function ($$v) {
                _vm.isExpenseSplittingOpen = $$v
              },
              expression: "isExpenseSplittingOpen",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }