var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn form" },
    [
      _c(
        "b-row",
        [
          _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL
            ? _c(
                "b-col",
                {
                  staticStyle: {
                    border: "1px solid #47c7bf",
                    "border-radius": "8px",
                  },
                  attrs: { sm: "4" },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _vm.expenseItem.images.length > 0
                            ? _c("carousel", {
                                attrs: {
                                  value: _vm.expenseItem.images,
                                  "num-visible": 2,
                                  "num-scroll": 1,
                                  orientation: "vertical",
                                  verticalViewPortHeight: "460px",
                                  contentClass: "main-carousel",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function (slotProps) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "parent cursor-pointer",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "child" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn-transparent",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showPicture(
                                                            slotProps
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Eye"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          staticStyle: {
                                                            "margin-top": "4px",
                                                          },
                                                          attrs: {
                                                            size: 22,
                                                            "stroke-width": 2,
                                                            color: "#00A09C",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn-transparent text-color-burning-tomato",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removePicture(
                                                            slotProps
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Trash2"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 22,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c("b-img-lazy", {
                                                staticClass: "px-1",
                                                staticStyle: {
                                                  "border-radius": "8px",
                                                },
                                                attrs: {
                                                  src: _vm.getImage(
                                                    slotProps.data
                                                  ),
                                                  alt: slotProps.data,
                                                  "fluid-grow": "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1090556148
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      class: `${
                        _vm.expenseItem.images.length > 1 ? "pb-5" : ""
                      }`,
                      staticStyle: { height: "100% !important" },
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex justify-content-center",
                          class: `${
                            _vm.expenseItem.images.length > 0
                              ? ""
                              : "align-items-center"
                          }`,
                        },
                        [
                          _c(
                            "div",
                            {
                              class: `${
                                _vm.expenseItem.images.length > 1 ? "pt-4" : ""
                              }`,
                              style: `${
                                _vm.expenseItem.images.length > 0
                                  ? "position: absolute; margin-top: -34px"
                                  : ""
                              }`,
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "w-138-px d-flex justify-content-center align-items-center",
                                  style: `${
                                    _vm.expenseItem.images.length > 1
                                      ? "margin: 12px 0 8px 0"
                                      : ""
                                  }`,
                                  attrs: {
                                    size: "sm",
                                    variant: "outline-primary",
                                    disabled: _vm.loadingImage,
                                  },
                                  on: { click: _vm.onImageAdded },
                                },
                                [
                                  _c("b-spinner", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loadingImage,
                                        expression: "loadingImage",
                                      },
                                    ],
                                    staticStyle: { "margin-bottom": "1px" },
                                    attrs: { small: "" },
                                  }),
                                  !_vm.loadingImage
                                    ? _c(_vm.getLucideIcon("PlusCircle"), {
                                        tag: "component",
                                        attrs: { size: 16, "stroke-width": 2 },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "px-2",
                                      staticStyle: { "margin-bottom": "-2px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(45, "Add images")
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            {
              attrs: {
                sm: `${
                  _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL ? 8 : 12
                }`,
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "3" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(1, "Date") } },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              "available-dates": {
                                start: new Date(_vm.expenseItem.date),
                                end: null,
                              },
                              locale: _vm.lang,
                              masks: _vm.masks,
                            },
                            on: { dayclick: _vm.handleDate },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ inputValue, togglePopover }) {
                                  return [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass: "br-none",
                                          attrs: {
                                            value: inputValue,
                                            readonly: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "cursor-pointer bg-color-white input-group-text-bg-white",
                                                on: {
                                                  click: function ($event) {
                                                    return togglePopover()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon("Calendar"),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color:
                                                        "rgba(6, 38, 62, .6)",
                                                      size: 18,
                                                      "stroke-width": 2.25,
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.expenseItem.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.expenseItem, "date", $$v)
                              },
                              expression: "expenseItem.date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("department-selector", {
                        attrs: {
                          "edit-data": _vm.expenseItem,
                          "is-submitted": _vm.isSubmitted,
                          "is-new": _vm.isNew,
                          "department-desktop-size": "4",
                          "category-desktop-size": "4",
                          "transport-desktop-size": "4",
                        },
                        on: {
                          change: _vm.handleDepartmentAndCatChange,
                          "department:selector:invalid": _vm.isInvalidateFields,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      attrs: {
                        sm: `${
                          _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL
                            ? 6
                            : 12
                        }`,
                      },
                    },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(8, "Description") } },
                        [
                          _c("b-form-textarea", {
                            staticStyle: { height: "7rem" },
                            model: {
                              value: _vm.expenseItem.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.expenseItem, "description", $$v)
                              },
                              expression: "expenseItem.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL
                    ? _c(
                        "b-col",
                        { attrs: { sm: "6" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("supplier-selector", {
                                    attrs: {
                                      title: _vm.FormMSG(9, "Supplier"),
                                      label: _vm.FormMSG(10, "Supplier"),
                                      placeholder: _vm.FormMSG(
                                        11,
                                        "Type to search ..."
                                      ),
                                      "supplier-id": _vm.expenseItem.supplierId,
                                      "show-map": false,
                                      "ocr-suppliers": _vm.ocrImages,
                                      "use-new-design": "",
                                      version: "1.0",
                                    },
                                    on: {
                                      "supplier:unselected": _vm.onUnselected,
                                      change: _vm.handleSupplierChanged,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(
                                          12,
                                          "Payment method"
                                        ),
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          options: _vm.paymentTypeOptions,
                                        },
                                        on: { change: _vm.handlePaymentMehod },
                                        model: {
                                          value: _vm.expenseItem.paiementType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.expenseItem,
                                              "paiementType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "expenseItem.paiementType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(13, "Amount (tax excl.)"),
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "number",
                                      step: "0.01",
                                      min: "0",
                                      placeholder: "0",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.expenseItem.amount,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.expenseItem, "amount", $$v)
                                      },
                                      expression: "expenseItem.amount",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#06263E",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(14, "VAT Code") } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "text",
                                  options: _vm.taxOptions,
                                  reduce: (option) => option.value,
                                  placeholder: "...",
                                  selectable: _vm.selectableItem,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option",
                                      fn: function (option) {
                                        return [
                                          option.disabled
                                            ? _c(
                                                "div",
                                                { staticClass: "text-bold" },
                                                [_vm._v(_vm._s(option.text))]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-color ml-3",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(option.text) +
                                                      " - " +
                                                      _vm._s(option.item.name) +
                                                      " (" +
                                                      _vm._s(
                                                        option.item.factor
                                                      ) +
                                                      "%)"
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1411684527
                                ),
                                model: {
                                  value: _vm.expenseItem.vatCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.expenseItem, "vatCode", $$v)
                                  },
                                  expression: "expenseItem.vatCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(15, "VAT amount") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "number",
                                      step: "0.01",
                                      min: "0",
                                      placeholder: "0",
                                    },
                                    model: {
                                      value: _vm.expenseItem.amountVat,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.expenseItem,
                                          "amountVat",
                                          $$v
                                        )
                                      },
                                      expression: "expenseItem.amountVat",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#06263E",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  16,
                                  "Total amount (tax incl.)"
                                ),
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "number",
                                      step: "0.01",
                                      min: "0",
                                      placeholder: "0",
                                    },
                                    model: {
                                      value: _vm.expenseItem.amountTotal,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.expenseItem,
                                          "amountTotal",
                                          $$v
                                        )
                                      },
                                      expression: "expenseItem.amountTotal",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#06263E",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.expenseItem.type === _vm.EXPENSE_TYPE.TRAVEL
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12" } },
                        [
                          _c("google-distance", {
                            attrs: {
                              "edit-data": _vm.expenseItem,
                              "to-location-label": _vm.FormMSG(18, "To"),
                              "from-location-label": _vm.FormMSG(17, "From"),
                              "to-location-error-message": _vm.FormMSG(
                                29,
                                "Arrival point, is required"
                              ),
                              "from-location-error-message": _vm.FormMSG(
                                30,
                                "Starting point, is required"
                              ),
                              "inline-validator": true,
                              "is-submitted": _vm.isSubmitted,
                            },
                            on: {
                              "google-distance:selector:invalid":
                                _vm.isInvalidateFields,
                              "google-distance:loading": _vm.calculateLoading,
                              change: _vm.handleDistanceChanges,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.expenseItem.type === _vm.EXPENSE_TYPE.TRAVEL
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(19, "Distance") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "expense-travel-distance",
                                      type: "number",
                                      placeholder: "0",
                                      min: "0",
                                      step: "0.001",
                                      readonly: _vm.loading,
                                    },
                                    model: {
                                      value: _vm.expenseItem.km,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.expenseItem, "km", $$v)
                                      },
                                      expression: "expenseItem.km",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c("b-input-group-text", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "font-weight-bold",
                                            staticStyle: { color: "#06263e" },
                                          },
                                          [_vm._v(_vm._s(_vm.distanceUnit))]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: `${
                                  _vm.FormMSG(20, "Rate") +
                                  " (" +
                                  _vm.distanceUnit +
                                  ")"
                                }`,
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      value: _vm.currentContract.kmRate
                                        ? _vm.currentContract.kmRate
                                        : 0,
                                      type: "number",
                                      step: "0.01",
                                      min: "0",
                                      placeholder: "0",
                                      readonly: "",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#06263E",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(21, "Amount") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "number",
                                      step: "0.01",
                                      min: "0",
                                      placeholder: "0",
                                      readonly: "",
                                    },
                                    model: {
                                      value: _vm.expenseItem.amountTotal,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.expenseItem,
                                          "amountTotal",
                                          $$v
                                        )
                                      },
                                      expression: "expenseItem.amountTotal",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#06263E",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "fieldset",
                      {
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside my-0 py-0 pb-0"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _c(
                          "legend",
                          {
                            staticClass: "text-color-rhapsody-in-blue-2",
                            class: `${
                              _vm.$screen.width <= 576
                                ? "card-inside"
                                : "scheduler-border"
                            }`,
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(22, "Flags")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "mt-2 px-2" },
                              [
                                _vm.$screen.width >= 992
                                  ? _c("b-table", {
                                      attrs: {
                                        items: _vm.flags,
                                        fields: _vm.flagFields,
                                        "sort-by": "short",
                                        "sort-desc": false,
                                        hover: "",
                                        "head-variant": "dark",
                                        bordered: "",
                                        small: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "cell(selected)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "px-2" },
                                                  [
                                                    _c("v-select", {
                                                      ref: `custom-flag-${data.item.flagId}`,
                                                      attrs: {
                                                        options:
                                                          data.item
                                                            .projectFlagItem,
                                                        label: "name",
                                                        reduce: (option) =>
                                                          option.id,
                                                        placeholder: "...",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.customFlagChanged(
                                                            $event,
                                                            data.item.flagId
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3569812607
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL
                ? _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "fieldset",
                          {
                            class: `${
                              _vm.$screen.width <= 576
                                ? "card-inside my-0 py-0 pb-0"
                                : "scheduler-border"
                            }`,
                          },
                          [
                            _c(
                              "legend",
                              {
                                staticClass: "text-color-rhapsody-in-blue-2",
                                class: `${
                                  _vm.$screen.width <= 576
                                    ? "card-inside"
                                    : "scheduler-border"
                                }`,
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(23, "Green detail")) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "pt-2 pb-3 pl-2",
                                    attrs: { sm: "12" },
                                  },
                                  [
                                    _c("co-two-clap", {
                                      attrs: {
                                        "parent-id": +_vm.expenseItem.id,
                                        "entity-type": 1,
                                        "for-create": true,
                                        "hide-btn-reviewed": "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex justify-content-end align-items-center",
                    },
                    [
                      _vm.expenseItem.validated === 4 ||
                      _vm.expenseItem.validated === 16
                        ? _c(
                            "b-button",
                            {
                              staticClass:
                                "w-138-px d-flex justify-content-center align-items-center mr-3",
                              attrs: {
                                size: "sm",
                                variant: "custom-outline-primary",
                                block: "",
                              },
                              on: { click: _vm.undoRejectExpenseItem },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.UNDO_2.name), {
                                tag: "component",
                                staticClass: "mr-2",
                                attrs: { size: 18 },
                              }),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "2px" } },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(47, "Undo")) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "b-button",
                            {
                              staticClass:
                                "w-138-px d-flex justify-content-center align-items-center mr-3",
                              attrs: {
                                size: "sm",
                                variant: "outline-danger",
                                block: "",
                              },
                              on: { click: _vm.rejectExpenseItem },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.X.name), {
                                tag: "component",
                                staticClass: "mr-2",
                                attrs: { size: 18 },
                              }),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "2px" } },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(24, "Reject")) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                      _vm.canEditExpense
                        ? _c(
                            "b-button",
                            {
                              staticClass:
                                "w-138-px d-flex justify-content-center align-items-center ml-3",
                              staticStyle: { "margin-top": "-2px" },
                              attrs: {
                                size: "sm",
                                variant: "outline-primary",
                                block: "",
                              },
                              on: { click: _vm.saveExpenseItem },
                            },
                            [
                              _c(_vm.getLucideIcon("Save"), {
                                tag: "component",
                                staticClass: "mr-2",
                                attrs: { size: 18 },
                              }),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "2px" } },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(46, "Save")) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("capture-images", {
            ref: "capture-images",
            attrs: {
              multiple: "",
              "preview-mode": "button",
              "parent-type": "expense_item",
              "parent-sub-type": "ticket",
              "parent-id": _vm.expenseItem.id,
              pictures: _vm.rendImagesListForPreviewer,
              "preview-modal-options": {
                zIndex: 1041,
                hideLikeDislike: true,
                hideCommentButton: true,
                hideDisLikeButton: true,
              },
              "dispatch-event-add-action": true,
            },
            on: {
              change: _vm.handleImgsTaken,
              "upload-image:loading": _vm.handleUploadImgStatus,
            },
          }),
          _c("reject-modal", {
            attrs: { "expense-item-id": _vm.expenseItem.id },
            on: {
              "reject-expense-item-modal:close": _vm.onRejectExpenseModalClosed,
              "reject-expense-item-modal:refresh": _vm.onExpenseItemUpdated,
            },
            model: {
              value: _vm.isRejectExpenseOpened,
              callback: function ($$v) {
                _vm.isRejectExpenseOpened = $$v
              },
              expression: "isRejectExpenseOpened",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }