<template>
	<b-modal
		id="expenseCarouselView"
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:size="`${expense.type === 2 ? 'lg' : 'xl'}`"
		centered
		cancel-variant="light"
		@hidden="emitEventClose"
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
	>
		<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
			<!-- <pre>{{ expense }}</pre> -->
			<!-- <pre>{{ expenseItems }}</pre> -->
			<!-- <pre>{{ expenseItem }}</pre> -->
			<b-row v-if="expenseItems.length > 1">
				<b-col>
					<div class="d-flex align-items-center justify-content-center">
						<div>
							<button class="btn-transparent text-color-rhapsody-in-blue" @click="back" :disabled="item ? index - 1 === 0 : true">
								<component :is="getLucideIcon('ChevronLeft')" color="rgba(6, 38, 62, 0.64)" />
							</button>
						</div>
						<div class="px-4">
							<div class="text-color-rhapsody-in-blue fs-16 fw-40">
								{{ FormMSG(7, 'Expense item') + '&nbsp;&nbsp;' + (item ? index : 1) + ' / ' + expenseItems.length }}
							</div>
						</div>
						<div>
							<button class="btn-transparent text-color-rhapsody-in-blue" @click="next" :disabled="item ? index === expenseItems.length : true">
								<component :is="getLucideIcon('ChevronRight')" color="rgba(6, 38, 62, 0.64)" />
							</button>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div class="wrap-status justify-content-center w-100">
						<div :class="`status ${item ? item.statusClass : ''}`" style="font-size: 0.84rem">
							{{ item ? item.validatedStatus : '' }}
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="mt-3"> <validation-viewer :item="item" /> </b-col>
			</b-row>
		</div>
		<template #modal-title>
			<b-row>
				<b-col cols="4">
					<div style="font-size: 1.25rem">{{ FormMSG(3, 'Expense sheet review') }}</div>
				</b-col>
				<b-col cols="4" class="text-center">
					<div style="font-size: 1.25rem">
						<span class="text-uppercase">{{ FormMSG(4 + expense.type, EXPENSE_NAME[expense.type]) }}</span>
						#{{ item ? item.id : '' }}
					</div>
				</b-col>
				<b-col cols="4"> </b-col>
			</b-row>
		</template>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button
					size="sm"
					variant="custom-primary-blue"
					class="w-350-px mr-3"
					style="margin-top: 8px"
					:disabled="watingSubmitForm"
					@click="validateExpense"
					block
				>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-show="watingSubmitForm" small />
						<div class="d-flex justify-content-center align-items-center" :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">
							<component v-if="!watingSubmitForm" :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :size="18" class="mr-2" />
							<div style="margin-top: 2px; letter-spacing: 0.05rem">{{ FormMSG(2, 'Validate Expense without rejected items') }}</div>
						</div>
					</div>
				</b-button>
				<b-button size="sm" variant="custom-outline-gray" class="w-138-px" :disabled="watingSubmitForm" @click="emitEventClose" block>
					{{ FormMSG(1, 'Close') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import ValidationViewer from '@/components/ExpenseService/CarouselView/ValidationViewer';
import { EXPENSE_NAME } from '@/shared/constants';
import _ from 'lodash';
import { validateExpense } from '@/cruds/expense.crud';
import { mapActions } from 'vuex';

export default {
	name: 'ExpenseCarouselViewModal',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		ValidationViewer
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		title: {
			type: String,
			required: false,
			default: ''
		},
		expense: {
			type: Object,
			required: true,
			default: null
		},
		expenseItem: {
			type: Object,
			required: false,
			default: null
		},
		expenseItems: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {
			watingSubmitForm: false,
			EXPENSE_NAME: EXPENSE_NAME,
			index: 1,
			item: null,
			id: 0
		};
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		emitEventClose() {
			this.$emit('expense-carousel-view-modal:closed');
		},
		next() {
			this.index = this.index + 1;
			this.item = this.expenseItems[this.index - 1];
		},
		back() {
			this.index = this.index - 1;
			this.item = this.expenseItems[this.index - 1];
		},
		async validateExpense() {
			const action = async () => {
				await validateExpense(+this.expense.id, '')
					.then(async (record) => {
						await this.getNotifications();
						this.$emit('expense-carousel-view-modal:reload');
					})
					.catch((error) => {
						console.log(error);
					});
			};
			this.confirmModal(action, this.FormMSG(8, 'Are you sure to validate all items ?'), 'success');
		}
	},
	watch: {
		expenseItem: {
			handler(value) {
				if (!_.isNil(value)) {
					if (!_.isUndefined(value.originalEvent)) {
						this.index = this.expenseItem.index + 1;
						this.item = this.expenseItem.data;
					}
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss" scoped></style>
