<template>
	<div class="animated fadeIn form">
		<b-row>
			<!-- <pre>{{ expenseItem }}</pre> -->
			<!-- <pre>{{ EXPENSE_TYPE }}</pre> -->
			<b-col sm="4" style="border: 1px solid #47c7bf; border-radius: 8px" v-if="expenseItem.type < EXPENSE_TYPE.TRAVEL">
				<b-row>
					<b-col>
						<carousel
							v-if="expenseItem.images.length > 0"
							:value="expenseItem.images"
							:num-visible="2"
							:num-scroll="1"
							orientation="vertical"
							verticalViewPortHeight="460px"
							contentClass="main-carousel"
						>
							<template #item="slotProps">
								<div class="parent cursor-pointer">
									<div class="child">
										<button type="button" class="btn-transparent" @click="showPicture(slotProps)">
											<component :is="getLucideIcon('Eye')" :size="22" :stroke-width="2" color="#00A09C" style="margin-top: 4px" />
										</button>
										<button type="button" class="btn-transparent text-color-burning-tomato" @click="removePicture(slotProps)">
											<component :is="getLucideIcon('Trash2')" :size="22" :stroke-width="2" />
										</button>
									</div>
									<b-img-lazy :src="getImage(slotProps.data)" :alt="slotProps.data" fluid-grow style="border-radius: 8px" class="px-1" />
								</div>
							</template>
						</carousel>
					</b-col>
				</b-row>
				<b-row style="height: 100% !important" :class="`${expenseItem.images.length > 1 ? 'pb-5' : ''}`">
					<b-col class="d-flex justify-content-center" :class="`${expenseItem.images.length > 0 ? '' : 'align-items-center'}`">
						<div
							:class="`${expenseItem.images.length > 1 ? 'pt-4' : ''}`"
							:style="`${expenseItem.images.length > 0 ? 'position: absolute; margin-top: -34px' : ''}`"
						>
							<b-button
								size="sm"
								variant="outline-primary"
								class="w-138-px d-flex justify-content-center align-items-center"
								@click="onImageAdded"
								:disabled="loadingImage"
								:style="`${expenseItem.images.length > 1 ? 'margin: 12px 0 8px 0' : ''}`"
							>
								<b-spinner v-show="loadingImage" small style="margin-bottom: 1px" />
								<component v-if="!loadingImage" :is="getLucideIcon('PlusCircle')" :size="16" :stroke-width="2" />
								<div class="px-2" style="margin-bottom: -2px">
									{{ FormMSG(45, 'Add images') }}
								</div>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</b-col>
			<b-col :sm="`${expenseItem.type < EXPENSE_TYPE.TRAVEL ? 8 : 12}`">
				<b-row>
					<b-col sm="3">
						<b-form-group :label="FormMSG(1, 'Date')">
							<v-date-picker
								v-model="expenseItem.date"
								:available-dates="{
									start: new Date(expenseItem.date),
									end: null
								}"
								:locale="lang"
								:masks="masks"
								@dayclick="handleDate"
							>
								<template v-slot="{ inputValue, togglePopover }">
									<b-input-group>
										<b-form-input :value="inputValue" class="br-none" readonly />
										<b-input-group-append>
											<b-input-group-text class="cursor-pointer bg-color-white input-group-text-bg-white" @click="togglePopover()">
												<component :is="getLucideIcon('Calendar')" color="rgba(6, 38, 62, .6)" :size="18" :stroke-width="2.25" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</template>
							</v-date-picker>
						</b-form-group>
					</b-col>
					<b-col sm="9">
						<department-selector
							:edit-data="expenseItem"
							:is-submitted="isSubmitted"
							:is-new="isNew"
							department-desktop-size="4"
							category-desktop-size="4"
							transport-desktop-size="4"
							@change="handleDepartmentAndCatChange"
							@department:selector:invalid="isInvalidateFields"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col :sm="`${expenseItem.type < EXPENSE_TYPE.TRAVEL ? 6 : 12}`">
						<b-form-group :label="FormMSG(8, 'Description')">
							<b-form-textarea v-model="expenseItem.description" style="height: 7rem" />
						</b-form-group>
					</b-col>
					<b-col sm="6" v-if="expenseItem.type < EXPENSE_TYPE.TRAVEL">
						<b-row>
							<b-col>
								<supplier-selector
									:title="FormMSG(9, 'Supplier')"
									:label="FormMSG(10, 'Supplier')"
									:placeholder="FormMSG(11, 'Type to search ...')"
									:supplier-id="expenseItem.supplierId"
									:show-map="false"
									@supplier:unselected="onUnselected"
									@change="handleSupplierChanged"
									:ocr-suppliers="ocrImages"
									use-new-design
									version="1.0"
								/>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="mt-3">
								<b-form-group :label="FormMSG(12, 'Payment method')">
									<b-form-select v-model="expenseItem.paiementType" :options="paymentTypeOptions" @change="handlePaymentMehod" />
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-row v-if="expenseItem.type < EXPENSE_TYPE.TRAVEL">
					<b-col sm="3">
						<b-form-group :label="FormMSG(13, 'Amount (tax excl.)')">
							<b-input-group>
								<b-form-input v-model="expenseItem.amount" type="number" step="0.01" min="0" placeholder="0" disabled />
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="3">
						<b-form-group :label="FormMSG(14, 'VAT Code')">
							<v-select
								v-model="expenseItem.vatCode"
								label="text"
								:options="taxOptions"
								:reduce="(option) => option.value"
								placeholder="..."
								:selectable="selectableItem"
							>
								<template #option="option">
									<div class="text-bold" v-if="option.disabled">{{ option.text }}</div>
									<div class="text-color ml-3" v-else>{{ option.text }} - {{ option.item.name }} ({{ option.item.factor }}%)</div>
								</template>
							</v-select>
						</b-form-group>
					</b-col>
					<b-col sm="3">
						<b-form-group :label="FormMSG(15, 'VAT amount')">
							<b-input-group>
								<b-form-input v-model="expenseItem.amountVat" type="number" step="0.01" min="0" placeholder="0" />
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="3">
						<b-form-group :label="FormMSG(16, 'Total amount (tax incl.)')">
							<b-input-group>
								<b-form-input v-model="expenseItem.amountTotal" type="number" step="0.01" min="0" placeholder="0" />
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row v-if="expenseItem.type === EXPENSE_TYPE.TRAVEL">
					<b-col sm="12">
						<google-distance
							:edit-data="expenseItem"
							:to-location-label="FormMSG(18, 'To')"
							:from-location-label="FormMSG(17, 'From')"
							:to-location-error-message="FormMSG(29, 'Arrival point, is required')"
							:from-location-error-message="FormMSG(30, 'Starting point, is required')"
							:inline-validator="true"
							:is-submitted="isSubmitted"
							@google-distance:selector:invalid="isInvalidateFields"
							@google-distance:loading="calculateLoading"
							@change="handleDistanceChanges"
						/>
					</b-col>
				</b-row>
				<b-row v-if="expenseItem.type === EXPENSE_TYPE.TRAVEL">
					<b-col sm="4">
						<b-form-group :label="FormMSG(19, 'Distance')">
							<b-input-group>
								<b-form-input
									v-model="expenseItem.km"
									id="expense-travel-distance"
									type="number"
									placeholder="0"
									min="0"
									step="0.001"
									:readonly="loading"
								/>
								<b-input-group-append>
									<b-input-group-text>
										<span class="font-weight-bold" style="color: #06263e">{{ distanceUnit }}</span>
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group :label="`${FormMSG(20, 'Rate') + ' (' + distanceUnit + ')'}`">
							<b-input-group>
								<b-form-input
									:value="currentContract.kmRate ? currentContract.kmRate : 0"
									type="number"
									step="0.01"
									min="0"
									placeholder="0"
									readonly
								/>
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group :label="FormMSG(21, 'Amount')">
							<b-input-group>
								<b-form-input v-model="expenseItem.amountTotal" type="number" step="0.01" min="0" placeholder="0" readonly />
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
							<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
								{{ FormMSG(22, 'Flags') }}
							</legend>
							<b-row>
								<b-col class="mt-2 px-2">
									<b-table
										v-if="$screen.width >= 992"
										:items="flags"
										:fields="flagFields"
										sort-by="short"
										:sort-desc="false"
										hover
										head-variant="dark"
										bordered
										small
									>
										<template v-slot:cell(selected)="data">
											<div class="px-2">
												<v-select
													:ref="`custom-flag-${data.item.flagId}`"
													:options="data.item.projectFlagItem"
													label="name"
													:reduce="(option) => option.id"
													placeholder="..."
													@input="customFlagChanged($event, data.item.flagId)"
												/>
											</div>
										</template>
									</b-table>
								</b-col>
							</b-row>
						</fieldset>
					</b-col>
				</b-row>
				<b-row v-if="expenseItem.type < EXPENSE_TYPE.TRAVEL">
					<b-col>
						<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
							<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
								{{ FormMSG(23, 'Green detail') }}
							</legend>
							<b-row>
								<b-col sm="12" class="pt-2 pb-3 pl-2">
									<co-two-clap :parent-id="+expenseItem.id" :entity-type="1" :for-create="true" hide-btn-reviewed />
								</b-col>
							</b-row>
						</fieldset>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="d-flex justify-content-end align-items-center">
						<b-button
							size="sm"
							variant="custom-outline-primary"
							class="w-138-px d-flex justify-content-center align-items-center mr-3"
							@click="undoRejectExpenseItem"
							block
							v-if="expenseItem.validated === 4 || expenseItem.validated === 16"
						>
							<component :is="getLucideIcon(ICONS.UNDO_2.name)" :size="18" class="mr-2" />
							<div style="margin-top: 2px">
								{{ FormMSG(47, 'Undo') }}
							</div>
						</b-button>
						<b-button
							size="sm"
							variant="outline-danger"
							class="w-138-px d-flex justify-content-center align-items-center mr-3"
							@click="rejectExpenseItem"
							block
							v-else
						>
							<component :is="getLucideIcon(ICONS.X.name)" :size="18" class="mr-2" />
							<div style="margin-top: 2px">
								{{ FormMSG(24, 'Reject') }}
							</div>
						</b-button>
						<!-- <b-button
							size="sm"
							variant="custom-outline-primary"
							class="w-200-px d-flex justify-content-center align-items-center"
							style="margin-top: -2px"
							@click="validateExpenseItem"
							block
						>
							<component :is="getLucideIcon('Check')" :size="18" class="mr-2" />
							<div style="margin-top: 2px">
								{{ FormMSG(25, 'Approve and go to next') }}
							</div>
						</b-button> -->
						<b-button
							size="sm"
							variant="outline-primary"
							class="w-138-px d-flex justify-content-center align-items-center ml-3"
							style="margin-top: -2px"
							@click="saveExpenseItem"
							block
							v-if="canEditExpense"
						>
							<component :is="getLucideIcon('Save')" :size="18" class="mr-2" />
							<div style="margin-top: 2px">
								{{ FormMSG(46, 'Save') }}
							</div>
						</b-button>
					</b-col>
				</b-row>
			</b-col>
			<capture-images
				ref="capture-images"
				multiple
				preview-mode="button"
				parent-type="expense_item"
				parent-sub-type="ticket"
				:parent-id="expenseItem.id"
				:pictures="rendImagesListForPreviewer"
				:preview-modal-options="{ zIndex: 1041, hideLikeDislike: true, hideCommentButton: true, hideDisLikeButton: true }"
				:dispatch-event-add-action="true"
				@change="handleImgsTaken"
				@upload-image:loading="handleUploadImgStatus"
			/>
			<reject-modal
				v-model="isRejectExpenseOpened"
				:expense-item-id="expenseItem.id"
				@reject-expense-item-modal:close="onRejectExpenseModalClosed"
				@reject-expense-item-modal:refresh="onExpenseItemUpdated"
			/>
			<!-- <Capture v-if="!$isPwa()" v-model="isCaptureModalOpen" multiple-img dynamic-crop-size :edit-data="editData" @change="handleImgsTaked" /> -->
		</b-row>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import SupplierSelector from '@/components/SupplierSelector';
import CurrencySvg from '@/components/Svg/Currency';
import Carousel from 'primevue/carousel';
import { EXPENSE_TYPE, SUPPLIER, FLAG_TYPE, ERROR_STRING } from '@/shared/constants';
import _ from 'lodash';
import { store } from '@/store';
import { getProjectFlags, addUpdateProjectFlagEntity } from '@/cruds/flag.crud';
import GoogleDistance from '@/components/GoogleDistance';
import CoTwoClap from '@/modules/carbonclap/components/green/CoTwoClap';
import { getFileExtension } from '@/shared/helpers';
import CaptureImages from '@/components/Packages/Captures/components/Main';
import RejectModal from '@/components/ExpenseService/CarouselView/RejectExpenseModal';
// import Capture from '@/components/ExpenseService/Capture';
import { undoRejectExpenseItem } from '@/cruds/expense.crud';

export default {
	name: 'ExpenseCarouselValidationViewer',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		'v-date-picker': DatePicker,
		Treeselect,
		SupplierSelector,
		CurrencySvg,
		Carousel,
		DepartmentSelector: () => ({
			component: import('@/components/DepartmentsSelection')
		}),
		GoogleDistance,
		CoTwoClap,
		CaptureImages,
		RejectModal
	},
	computed: {
		...mapGetters({
			paiementTypes: 'expenses/paiementTypes',
			taxOptions: 'flag/taxOptions'
		}),
		flagFields() {
			return [
				{
					key: 'short',
					label: this.FormMSG(26, 'Flag'),
					class: 'text-center',
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(27, 'Description'),
					sortable: false
				},
				{
					key: 'selected',
					label: this.FormMSG(28, 'Selection'),
					class: 'text-center',
					sortable: false
				}
			];
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		paymentTypeOptions() {
			return this.paiementTypes.map(({ id, name }) => {
				return {
					value: id,
					text: this.FormMSG(40 + id, name)
				};
			});
		},
		rendImagesListForPreviewer() {
			if (this.expenseItem.images.length > 0) {
				return this.expenseItem.images.map((img) => {
					return {
						xid: img,
						src: `${process.env.VUE_APP_GQL}/images/${img}`,
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
						ext: getFileExtension(img)
					};
				});
			}

			return [];
		},
		currentContract() {
			return this.getCurrentContract();
		},
		canEditExpense() {
			return store.canEditExpense();
		},
		isProd() {
			return store.isProd();
		}
	},
	props: {
		item: {
			type: Object,
			required: true,
			default: null
		}
	},
	data() {
		return {
			expenseItem: null,
			masks: {
				input: 'DD/MM/YYYY'
			},
			ocrImages: [],
			flags: [],
			typeco2: null,
			loading: false,
			images: [],
			EXPENSE_TYPE: EXPENSE_TYPE,
			expenseIndex: 0,
			isSubmitted: false,
			isNew: false,
			isInvalid: false,
			SUPPLIER: SUPPLIER,
			loadingImage: false,
			isRejectExpenseOpened: false,
			defaultValueCustomFlags: [],
			newValueCustomFlags: []
		};
	},
	async mounted() {
		this.$nextTick(async () => {
			await this.getTaxOptions();
			await getProjectFlags(FLAG_TYPE.DEFAULT_FLAG)
				.then((records) => {
					this.flags = records.customFlags;
					this.initValueCustomFlags(this.flags);
				})
				.catch((error) => {
					console.log({ error });
				});
			console.log({ refs: this.$refs });
			this.setDefaultCustomFlags();
			// this.$refs[`custom-flag-5`].$props.value = '555';
		});
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications',
			getTaxOptions: 'flag/getTaxOptions'
		}),
		handleDate(payload) {},
		onUnselected(payload) {},
		handleSupplierChanged(payload) {},
		handlePaymentMehod(payload) {},
		handleDepartmentAndCatChange({ category, department, subCategory }) {
			console.log('grrrrr', { category, department, subCategory });
		},
		isInvalidateFields(payload) {
			this.isInvalid = payload;
		},
		selectableItem(item) {
			return !item.disabled;
		},
		calculateLoading(payload) {
			this.loading = payload;
		},
		handleDistanceChanges(payload) {},
		getImage(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		showPicture(data) {
			const images = this.expenseItem.images.map((img) => {
				return {
					xid: img,
					src: `${process.env.VUE_APP_GQL}/images/${img}`,
					thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
					ext: getFileExtension(img)
				};
			});

			this.$previewImages({
				images,
				focusIndex: data.index,
				options: {
					presentationMode: 'doc',
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: false
				}
			});
		},
		removePicture(data) {
			const action = async () => {
				await this.$axios.$get(`/removeimage/${this.getFileName(this.expenseItem.images[data.index])}`).then((response) => {
					if (ERROR_STRING.ITEM_NOT_FOUND === response) {
						this.createToastForMobile(this.FormMSG(47, 'Error'), this.FormMSG(48, 'Image not found'), 'danger');
					} else {
						this.expenseItem.images.splice(data.index, 1);
					}
				});
			};

			this.confirmModal(action, this.FormMSG(44, 'Are you sure ?'), 'danger');
		},
		getFileName(filename) {
			return filename.substring(0, filename.lastIndexOf('.'));
		},
		/**
		 * @param {Array} images
		 */
		async handleImgsTaken(images) {
			this.expenseItem.images = images;
		},
		handleUploadImgStatus() {},
		onImageAdded() {
			this.$refs['capture-images'].$refs['add-images-none-pwa'].click();
		},
		async rejectExpenseItem() {
			const action = async () => {
				// console.log({ id: this.expenseItem.id });
				this.isRejectExpenseOpened = true;
			};
			this.confirmModal(action, this.FormMSG(49, 'Are you sure to reject this expense item ?'), 'danger');
		},
		async validateExpenseItem() {
			const action = async () => {
				console.log({ id: this.expenseItem.id });
			};
			this.confirmModal(action, 'Are you sure to validate this expense item ?', 'success');
		},
		async saveExpenseItem() {
			console.log({ expenseItem: this.expenseItem });
		},
		onRejectExpenseModalClosed() {
			this.isRejectExpenseOpened = false;
		},
		customFlagChanged(payload, flagId) {
			if (!_.isNil(payload)) {
				const items = this.flags.filter((flag) => +flag.flagId === +flagId)[0].projectFlagItem;
				const item = items.filter((item) => +item.id === +payload)[0];
				for (let i = 0; i < this.newValueCustomFlags.length; i++) {
					if (+this.newValueCustomFlags[i].flagId === +flagId) {
						this.newValueCustomFlags[i].item = item;
					}
				}
			} else {
				for (let i = 0; i < this.newValueCustomFlags.length; i++) {
					if (+this.newValueCustomFlags[i].flagId === +flagId) {
						this.newValueCustomFlags[i].item = null;
					}
				}
			}
		},
		initValueCustomFlags(flags) {
			for (const flag of flags) {
				this.newValueCustomFlags.push({
					flagId: flag.flagId,
					item: null
				});

				this.defaultValueCustomFlags.push({
					flagId: flag.flagId,
					item: null
				});

				for (let i = 0; i < this.defaultValueCustomFlags; i++) {
					const flag = this.defaultValueCustomFlags[i];
					const customFlag = this.expenseItem.projectFlagItems.customFlags.filter((item) => !_.isNil(item) && +flag.flagId === +item.flagId);
					if (customFlag.length > 0) {
						this.defaultValueCustomFlags[i].item = customFlag[0];
					}
				}
			}
		},
		async updateExpenseFlagItems() {
			for (let i = 0; i < this.newValueCustomFlags; i++) {
				/**
				 * right :: this.newValueCustomFlags, left :: this.defaultValueCustomFlags
				 * CASE ::
				 * 1- If defaultValueCustomFlags is null && newValueCustomFlags not null, we pass directly to the insertion (we use newValueCustomFlags value)
				 * 2- If newValueCustomFlags is null && defaultValueCustomFlags not null, we go to the removal (we use defaultValueCustomFlags value)
				 * 3- if newValueCustomFlags && defaultValueCustomFlags are not null and differents, we delete defaultValueCustomFlags value && insert newValueCustomFlags value
				 */
				if (_.isNil(this.defaultValueCustomFlags[i].item) && !_.isNil(this.newValueCustomFlags[i].item)) {
					// insert
					await addUpdateProjectFlagEntity(0, this.newValueCustomFlags[i].item).catch((error) => {
						console.error({ error });
					});
				} else if (_.isNil(this.newValueCustomFlags[i].item) && !_.isNil(this.defaultValueCustomFlags[i].item)) {
					// delete
					await addUpdateProjectFlagEntity(+this.defaultValueCustomFlags[i].item.id, this.defaultValueCustomFlags[i].item, true).catch((error) => {
						console.error({ error });
					});
				} else if (
					!_.isNil(this.newValueCustomFlags[i].item) &&
					!_.isNil(this.defaultValueCustomFlags[i].item) &&
					+this.newValueCustomFlags[i].item.id !== +this.defaultValueCustomFlags[i].item.id
				) {
					// delete
					await addUpdateProjectFlagEntity(+this.defaultValueCustomFlags[i].item.id, this.defaultValueCustomFlags[i].item, true).catch((error) => {
						console.error({ error });
					});

					// insert
					await addUpdateProjectFlagEntity(0, this.newValueCustomFlags[i].item).catch((error) => {
						console.error({ error });
					});
				}
			}
		},
		onExpenseItemUpdated(payload) {
			this.isRejectExpenseOpened = false;
		},
		async undoRejectExpenseItem() {
			await undoRejectExpenseItem(this.expenseItem.id)
				.then(async (record) => {
					await this.getNotifications();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		setDefaultCustomFlags() {
			for (const flag of this.flags) {
				const customFlag = this.expenseItem.projectFlagItems.customFlags.filter((item) => !_.isNil(item) && +flag.flagId === +item.flagId);
				this.$refs[`custom-flag-${+flag.flagId}`].$props.value = customFlag.length > 0 ? customFlag[0] : null;
			}
		}
	},
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					this.expenseItem = _.cloneDeep(value);
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss">
.text-bold {
	font-weight: 600;
	font-size: 1rem;
}

.text-color {
	font-size: 12px !important;
	color: rgba(6, 38, 62, 0.84) !important;
}

.main-carousel {
	.parent {
		position: relative;
		width: 100%;
		height: 100%;
		border: 0.005rem solid rgba(226, 230, 235, 0.85);
		border-radius: 8px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.child {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: none;
			display: none;
		}
	}

	.parent:hover {
		.child {
			display: block;
		}
	}
}
</style>
