<template>
	<b-modal
		id="expenseCarouselView"
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		size="xl"
		centered
		cancel-variant="light"
		@hidden="emitEventClose"
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
	>
		<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
			<!-- <pre>{{ expenseItem }}</pre> -->
			<b-row class="form" v-if="expenseItem">
				<!-- <pre>{{ expenseItem }}</pre> -->
				<b-col sm="8" class="px-0">
					<div class="sub-title pb-3" style="margin-left: 0">
						{{ FormMSG(5, 'Initial Expense item') }}
					</div>
					<b-row class="px-3 pb-2">
						<b-col sm="4">
							<b-form-group :label="FormMSG(6, 'Amount (tax excl.)')">
								<b-input-group>
									<b-form-input :value="expenseItem.amount" type="number" readonly />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group :label="FormMSG(7, 'Total VAT')">
								<b-input-group>
									<b-form-input :value="expenseItem.amountVat" type="number" readonly />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group :label="FormMSG(8, 'Total amount (tax incl.)')">
								<b-input-group>
									<b-form-input :value="expenseItem.amountTotal" type="number" readonly />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<div class="sub-title pb-3" style="margin-left: 0">
						{{ FormMSG(9, 'New splitting') }}
					</div>
					<b-row class="pb-2">
						<b-col>
							<div style="margin-left: 7.25rem; color: rgba(6, 38, 62, 0.64)">
								{{ FormMSG(11, 'Note: initial expense item will be splitted into different lines') }}
							</div>
							<b-row>
								<b-col class="px-5">
									<b-table-simple small id="expense-splitting">
										<b-thead head-variant="dark">
											<b-tr style="line-height: 24px" class="text-center">
												<b-th style="border: none !important; background-color: transparent !important">&nbsp;</b-th>
												<b-th>{{ FormMSG(12, 'Amount (tax excl.)') }}</b-th>
												<b-th>{{ FormMSG(13, 'VAT Code') }}</b-th>
												<b-th>{{ FormMSG(14, 'VAT Amount') }}</b-th>
												<b-th>{{ FormMSG(15, 'Total amount (tax incl.)') }}</b-th>
												<b-th>&nbsp;</b-th>
											</b-tr>
										</b-thead>
										<b-tbody class="text-right">
											<b-tr style="line-height: 24px">
												<b-td style="border: none !important">&nbsp;</b-td>
												<b-td>303.2 €</b-td>
												<b-td class="text-center">T20</b-td>
												<b-td>75.8 €</b-td>
												<b-td>379 €</b-td>
												<b-td class="text-center">
													<component
														:is="getLucideIcon(ICONS.TRASH.name)"
														:color="ICONS.TRASH.color"
														:stroke-width="2.5"
														:size="18"
														class="cursor-pointer"
													/>
												</b-td>
											</b-tr>
										</b-tbody>
										<b-tfoot class="text-right">
											<b-tr style="font-size: 1rem">
												<b-th class="text-left" style="border-top: 0.005rem solid rgba(226, 230, 235, 0.85) !important">{{
													FormMSG(16, 'TOTAL')
												}}</b-th>
												<b-th>1,303.20 €</b-th>
												<b-th colspan="2">75.80 €</b-th>
												<b-th>1,379.00 €</b-th>
												<b-th class="text-left">&nbsp;</b-th>
											</b-tr>
										</b-tfoot>
									</b-table-simple>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<b-row class="px-5">
						<div class="d-flex justify-content-end w-100">
							<b-button size="sm" variant="outline-primary" class="w-138-px d-flex justify-content-center align-items-center">
								<component :is="getLucideIcon('PlusCircle')" :size="16" :stroke-width="2" />
								<div class="px-2" style="margin-bottom: -2px">
									{{ FormMSG(10, 'Add Splitting') }}
								</div>
							</b-button>
						</div>
					</b-row>
				</b-col>
				<b-col sm="4" style="border: 1px solid #47c7bf; border-radius: 8px">
					<carousel
						v-if="expenseItem.images.length > 0"
						:value="expenseItem.images"
						:num-visible="1"
						:num-scroll="1"
						orientation="vertical"
						verticalViewPortHeight="200px"
						contentClass="main-carousel"
					>
						<template #item="slotProps">
							<div class="parent cursor-pointer">
								<div class="child">
									<button type="button" class="btn-transparent" @click="showPicture(slotProps)">
										<component :is="getLucideIcon('Eye')" :size="22" :stroke-width="2" color="#00A09C" style="margin-top: 4px" />
									</button>
									<button type="button" class="btn-transparent text-color-burning-tomato" @click="removePicture(slotProps)">
										<component :is="getLucideIcon('Trash2')" :size="22" :stroke-width="2" />
									</button>
								</div>
								<b-img-lazy :src="getImage(slotProps.data)" :alt="slotProps.data" fluid-grow style="border-radius: 8px" class="px-1" />
							</div>
						</template>
					</carousel>
				</b-col>
			</b-row>
		</div>
		<template #modal-title>
			<b-row>
				<b-col cols="4">
					<div style="font-size: 1.25rem">{{ FormMSG(3, 'Expense TVA Splitting') }}</div>
				</b-col>
				<b-col cols="4" class="text-center">
					<div style="font-size: 1.25rem">
						<!-- <span class="text-uppercase">{{ 'TICKET #3606' }}</span> -->
						<span class="text-uppercase">{{ FormMSG(4 + expenseItem.type, EXPENSE_NAME[expenseItem.type]) }}</span>
						#{{ expenseItem ? expenseItem.id : '' }}
					</div>
				</b-col>
				<b-col cols="4"> </b-col>
			</b-row>
		</template>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<!-- <b-button size="sm" variant="custom-primary-blue" class="w-138-px mr-3" style="margin-top: 8px" :disabled="watingSubmitForm" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-show="watingSubmitForm" small />
						<div class="d-flex justify-content-center align-items-center" :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">
							<component v-if="!watingSubmitForm" :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :size="18" class="mr-2" />
							<div style="margin-top: 2px; letter-spacing: 0.05rem">{{ FormMSG(2, 'Validate Expense without rejected items') }}</div>
						</div>
					</div>
				</b-button> -->
				<b-button size="sm" variant="custom-outline-gray" class="w-138-px" @click="emitEventClose" block>
					{{ FormMSG(1, 'Close') }}
				</b-button>
				<b-button size="sm" variant="outline-primary" class="w-138-px ml-3" style="margin-top: 0px" block>
					{{ FormMSG(2, 'Save') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { EXPENSE_NAME } from '@/shared/constants';
import CurrencySvg from '@/components/Svg/Currency';
import _ from 'lodash';
import Carousel from 'primevue/carousel';
import { getFileExtension } from '@/shared/helpers';

export default {
	name: 'ExpenseSplittingModal',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		CurrencySvg,
		Carousel
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		expenseItem: {
			type: Object,
			required: false,
			default: null
		}
	},
	data() {
		return {
			EXPENSE_NAME: EXPENSE_NAME
		};
	},
	methods: {
		emitEventClose() {
			this.$emit('expense-splitting-modal:closed');
		},
		getImage(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		showPicture(data) {
			const images = this.expenseItem.images.map((img) => {
				return {
					xid: img,
					src: `${process.env.VUE_APP_GQL}/images/${img}`,
					thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
					ext: getFileExtension(img)
				};
			});

			this.$previewImages({
				images,
				focusIndex: data.index,
				options: {
					presentationMode: 'doc',
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: false
				}
			});
		},
		removePicture(data) {
			const action = async () => {
				await this.$axios.$get(`/removeimage/${this.getFileName(this.expenseItem.images[data.index])}`).then((response) => {
					if (ERROR_STRING.ITEM_NOT_FOUND === response) {
						this.createToastForMobile(this.FormMSG(47, 'Error'), this.FormMSG(48, 'Image not found'), 'danger');
					} else {
						this.expenseItem.images.splice(data.index, 1);
					}
				});
			};

			this.confirmModal(action, this.FormMSG(44, 'Are you sure ?'), 'danger');
		},
		getFileName(filename) {
			return filename.substring(0, filename.lastIndexOf('.'));
		}
	}
};
</script>
<style lang="scss" scoped>
#expense-splitting .table,
tbody td,
.thead-dark th {
	border: 0.005rem solid rgba(226, 230, 235, 0.85) !important;
	border-collapse: collapse;
	font-size: 12px;
	letter-spacing: 0.05rem;
}

.main-carousel {
	.parent {
		position: relative;
		width: 100%;
		height: 100%;
		border: 0.005rem solid rgba(226, 230, 235, 0.85);
		border-radius: 8px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.child {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: none;
			display: none;
		}
	}

	.parent:hover {
		.child {
			display: block;
		}
	}
}
</style>
